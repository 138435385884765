require('jquery')

$(function(){
  if(window.parent.screen.width>768){
    $('.openModal').click(function(){
      var target=$(this).data('target');
      var modal=document.getElementById(target);
      $(modal).fadeIn()});
    $('#closeModal , #modalBg').click(function(){
      var target=$(this).data('target');
      var modal=document.getElementById(target);
      $(modal).fadeOut()})}
  else{
    $(".openModal").click(function(){
      var target=$(this).data('target');
      var spTarget='sp'+target;
      var modal=document.getElementById(spTarget);
      $(modal).slideToggle()});
    $('#spCloseModal , #spModalBg').click(function(){
      var target=$(this).data('target');
      var spTarget='sp'+target;
      var modal=document.getElementById(spTarget);
      $(modal).slideToggle()})
    }
});